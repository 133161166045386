export const InitialState = {
  hamburgerMenu: false,
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_HAMBURGER_MENU":
      return {
        ...state,
        hamburgerMenu: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
